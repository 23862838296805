import { isValidElement, useMemo, useState } from 'react';
import YouTube from 'react-youtube';
import { useWindowsSize } from 'ui/hooks/useWindowSize';
import { ResponsiveBreakpoint } from 'ui/components/ResponsiveImage';
import { ResponsiveImageWrapper } from 'ui/components/ResponsiveImageWrapper';
import ReactPlayer from 'react-player/vimeo';
import clsx from 'clsx';

const defaultPlayerOptions = {
  autoplay: 1,
  controls: 0,
  playsinline: 1,
  modestbranding: 1,
  start: 0,
  mute: 1,
  rel: 0,
  fs: 1,
};

/**
 * @type {import('react').FC<{ data: { thumb: { time: string; img: { src: string; alt: string; } | React.ReactElement } | React.ReactElement; video: { id: string; videoURL?: string; videoProvider?: 'YOUTUBE' | 'VIMEO' } }; className?: string; playIcon?: string; }>}
 */
export const VideoInternal = props => {
  const { className, data, isNoscript = false, playIcon } = props;
  const { thumb, video } = data || {};

  const [isVideoPlayed, setIsVideoPlayed] = useState(false);
  const [isBeingHovered, setIsBeingHovered] = useState(false);
  const [playerOptions, setPlayerOptions] = useState(defaultPlayerOptions);
  const [windowWidth] = useWindowsSize();

  const events = useMemo(() => {
    const handleClick = () => {
      setIsVideoPlayed(state => !state);
      setPlayerOptions({
        ...playerOptions,
        controls: 1,
        mute: 0,
      });
    };

    const clickEvents = {
      onClick: handleClick,
      onKeyDown: handleClick,
    };

    if (windowWidth <= ResponsiveBreakpoint) {
      return clickEvents;
    }

    return {
      ...clickEvents,
      onMouseEnter: () => setIsBeingHovered(true),
      onMouseLeave: () => setIsBeingHovered(false),
    };
  }, [playerOptions, windowWidth]);

  const showVideoPlayer = isBeingHovered || isVideoPlayed; // only render the video player when the user either hovers on the section or clicks on the play button
  const getVideoPlayer = () => {
    switch(video.videoProvider) {
      case 'YOUTUBE': return <YouTube videoId={video.id} opts={{ playerVars: playerOptions }} data-elastic-name="video" data-testid="video" />;
      case 'VIMEO': return <ReactPlayer url={video.videoURL} muted={!isVideoPlayed} playing={showVideoPlayer} controls data-elastic-name="video" data-testid="video" />;
      // Adding default as youtube, because it might cause issues for existing docs
      default: return <YouTube videoId={video.id} opts={{ playerVars: playerOptions }} data-elastic-name="video" data-testid="video" />;
    }
  }
  
  return !video ? null : (
    <div
      className={clsx(
        '[ w-full lg:w-7/10 ][ mx-auto my-0 pt-3/5 lg:pt-2/5 ][ relative ][ [&_iframe]:w-full [&_iframe]:h-full [&_iframe]:absolute [&_iframe]:left-0 [&_iframe]:top-0 [&_iframe]:z-0 ]',
        className,
      )}
    >
      <div
        role="presentation"
        data-testid="videoContainer"
        {...events}
        className={clsx(
          '[ group ][ rounded-lg ][ w-full h-full overflow-hidden ][ absolute left-0 top-0 z-1 ][ cursor-pointer ][ before:content-empty before:transition-all before:duration-300 before:bg-gray-10/30 hover:before:bg-gray-10/50 before:w-full before:h-full before:overflow-hidden before:absolute before:left-0 before:top-0 before:z-1 ]',
          {
            '[ z-0 ][ before:-z-1 ]': isVideoPlayed,
          },
        )}
      >
        {showVideoPlayer && getVideoPlayer()}
        {!showVideoPlayer &&
          (isValidElement(thumb)
            ? thumb
            : thumb?.img && (
                <ResponsiveImageWrapper
                  src={thumb.img.src}
                  alternativeText={thumb.img.alt}
                  data-testid="thumbnail"
                  layout="fill"
                  isNoscript={isNoscript}
                  {...thumb.img}
                />
              ))}
        {!isVideoPlayed && thumb?.img && thumb?.time && (
          <p
            data-testid="time"
            className="[ text-white text-14 md:text-20 ][ m-0 ][ absolute left-1/2 bottom-6 md:bottom-10 z-1 ][ -translate-x-1/2 ]"
          >
            {thumb.time}
          </p>
        )}
        {!isVideoPlayed && ( 
          <img
            src={playIcon || "/img/icons/icon-play-video.svg"}
            alt="icon"
            loading="lazy"
            data-testid="playIcon"
            className="[ transition-all duration-300 ][ w-8 md:w-13.25 ][ absolute left-1/2 top-1/2 z-1 ][ -translate-x-1/2 -translate-y-1/2 group-hover:scale-150 ]"
          />
        )}
      </div>
    </div>
  );
};
